<template>
  <section class="app-ecommerce-details">
    <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <b-col md="6" class="mb-2">
        <b-card class="
            h-100
            cardH
            d-flex
            justify-content-md-center
            align-items-center
            text-center
          ">
          <b-row class="
              my-2
              d-flex
              justify-content-md-center
              align-items-center
              text-center
            ">
            <!-- Right: Attribute Details -->
            <b-col cols="12" md="12" class="
                d-flex
                justify-content-md-center
                align-items-center
                text-center
              ">
              <!-- Attribute Name -->
              <div class="">
                <span v-for="(translation, index) in attrData.translation" style="font-size: 25px; margin: auto"
                  :key="index">
                  {{ translation.name }}
                  <span v-if="
                    attrData.translation.length > 1 &&
                    index < attrData.translation.length - 1
                  ">-</span>
                </span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-card class="h-100">
          <div class="d-flex justify-content-center">
            <b-button block v-if="authPermissions.includes('delete')" @click="deleteData(attrData.id)"
              variant="outline-danger" class="mr-1 mt-1">
              Delete
            </b-button>
          </div>
          <hr />
          <div class="
              d-flex
              justify-content-md-center
              align-items-center
              text-center
            ">
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ attrData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Updated at</h6>
              <h3 class="mb-0">
                {{ attrData.updated_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-card title="Descritpion">
          <!-- Attribute Translations -->
          <b-card-body>
            <b-tabs align="center" class="text-center">
              <b-tab v-for="(translation, index) in attrData.translation"
                :title="translation.locale == 'en' ? 'English' : 'Arabic'" :key="index">
                <div>
                  <b-card-text v-html="translation.name"></b-card-text>
                </div>
                <div>
                  <b-button v-if="authPermissions.includes('update')" v-b-popover.hover.top="'Update Attribute'"
                    class="mt-1" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" type="submit"
                    :to="{ path: '/Attributes/Update/' + translation.id }">
                    Update
                  </b-button>
                  <b-button v-if="
                    authPermissions.includes('delete') &&
                    translation.locale != 'en' &&
                    translation.locale != 'ar'
                  " v-b-popover.hover.top="'Delete Translation'" class="mr-1" size="sm"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" type="submit"
                    @click="deleteAttr(translation.id)">
                    Delete
                  </b-button>
                </div>
              </b-tab>
            </b-tabs>
            <b-button v-if="authPermissions.includes('create')" block class="mt-2" variant="success" size="md"
              :to="{ path: '/Attributes/Translation/Add/' + id }">Add New Translation To Text</b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card title="Catgories">
              <!-- prices table -->
              <b-table
              responsive
              :items="attrData.categories"
              :fields="categoryFields"
            >
            <template #cell(sort)="data">
              <p>{{ data.item.pivot.sort }}</p>
            </template>
            <template #cell(is_include_name)="data">
              <span v-if="data.item.pivot.is_include_name">
                      <img width="20" :src="require('@/assets/images/icons/checked-true.svg')" />
                    </span>
            </template>
            <template #cell(icon)="data">
              <b-avatar :src="data.value" />
            </template>
            <template #cell(actions)="items">
              <div>
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px;"
                  class="btn-icon rounded-circle"
                  :to="{ path: '/Show-Category/' + items.item.id }"
                  variant="success"
                >
                  <feather-icon
                    style="width: 18px; height: 18px;"
                    icon="EyeIcon"
                  />
                </b-button>
              
              </div>
            </template>
            </b-table>
        </b-card>
      </b-col>

      
      <b-col cols="12">
        <b-card>
          <b-row>
            <!-- sorting -->
            <b-col md="3" sm="6" class="m-1">
              <b-form-group label="Sort" label-cols-sm="2" label-align-sm="right" label-size="sm"
                label-for="sortBySelect" class="mb-0">
                <b-input-group size="sm">
                  <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-25">
                  </b-form-select>
                  <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- filter -->
            <b-col md="3" class="m-1">
              <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                  <b-input-group-append>
                    <b-button variant="primary" @click="searchData">
                      Search
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- table -->
            <b-col cols="12">
              <b-table id="attributesTable" ref="values" striped hover responsive class="position-relative"
                :items="values" :fields="fields">
                <template #cell(actions)="items">
                  <!-- show button value -->
                  <b-button class="mr-1" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
                    type="submit" :to="{ path: '/Values/Show/' + items.item.id }">
                    <feather-icon style="width: 18px; height: 18px;" icon="EyeIcon" />
                  </b-button>



                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select id="perPageSelect" @change="fetchData" v-model="perPage" size="sm" :options="pageOptions"
                  class="w-50" />
              </b-form-group>
            </b-col>
            <div>
              <!-- start pagination -->
              <b-pagination @input="fetchValuesAttributeData()" aria-controls="attributesTable" v-model="currentPage"
                :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
            </div>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
// import axios from '@/libs/axios'
export default {
  mixins: [heightTransition],
  data() {
    return {
      authPermissions: [],
      id: 0,
      attrData: {},
      errors_back: '',
      showDismissibleAlert: false,
      values: [],
      sortDirection: 'desc',
      sortBy: 'id',
      sortDesc: true,
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      // table fields
      categoryFields:[
        {
          key: 'icon',
          label: 'Icone',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'sort',
          label: 'Sort',
        },
        {
          key: 'is_include_name',
          label: 'Include Name',
        },
        {
          key: 'actions',
        },
      ],
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'actions',
        },
      ],
    }
  },
  watch: {
    sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchValuesAttributeData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchValuesAttributeData()
    },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchValuesAttributeData()
      }
    },
  },
  async created() {
    this.authPermissions = this.$store.state.authPermissions
    this.id = this.$route.params.id
    await this.fetchData()
    await this.fetchValuesAttributeData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
  },
  methods: {
    searchData() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchValuesAttributeData()
    },
    async fetchData() {
      // fetch data of attribute
      await axios
        .get('attributes/' + this.id)
        .then((result) => {
          this.attrData = result.data.data
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    async fetchValuesAttributeData() {
      // fetch data of attribute
      var url = null
      if (this.filter == null) {
        url = 'attributes/' + this.$route.params.id +
          '/values/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '?page=' +
          this.currentPage
      } else {
        url = 'attributes/' + this.$route.params.id +
          '/values/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }

      // fetch data of values
      axios
        .get(url)
        .then((result) => {
          this.totalRows = result.data.data.meta.total
          this.values.length = 0

          this.values = result.data.data.data
          this.$refs.values.refresh()
          return this.values
        })
        .catch((err) => {
          //console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // delete attribute fuinction
    deleteAttr(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete translation.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('attributes/translations/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete attribute.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('attributes/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.$router.back(1)
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
<style scoped>
.cardH {
  margin-bottom: 0px;
}
</style>
